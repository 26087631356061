.menu-container {
    position: absolute;
    z-index: 1050;
    background-color: white;
    border: 1px solid silver;
    padding: 0px;
    margin: 0px;
    border-radius: 0.5rem;
    box-shadow:  10px 10px 17px -3px rgba(0,0,0,0.75);
}

.menu-container ul {
    list-style-type: none;
    margin:0px;
    padding: 0px;
}

.menu-container ul li {
    /* width: 100%; */
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    padding: 0.5rem 1rem;
    margin: 0px;
}

.menu-container ul li.menu-header {
    font-weight: bold;
    pointer-events: none;
    border-bottom: 1px solid silver;
    border-top: 1px solid silver;
    padding: 0.5rem;
}

.menu-container ul li:hover {
    background-color:#F2F2F2;
    cursor: pointer;
}