.print {
    display: none;
}

@media print {

    @page {
        size: 8.5in 11in;
        margin: 0.5cm;
    }

    body {
        margin: 0;
        padding: 0.5cm;
        color: #000;
        background-color: #fff;
        /* border: 1px solid #000; */
    }

    .print {
        display: block;
    }

    .no-print {
        display: none !important;
    }
}

